[theme="light"] {
  --bg-color-2: #FFFFFF;
  --bg-color-2-inverted: #171717;
  --bg-color-3: rgba(23, 23, 23, 0.05);
  --bg-color-4: rgba(23, 23, 23, 0.1);
  --text-color-1: #171717;
  --text-color-1-inverted: #FFFFFF;
  --text-color-2: rgba(23, 23, 23, 0.5);

  --btn-color-1: #171717;
  --btn-text-color-1: #FFFFFF;

  --input-color-1: rgba(23, 23, 23, 0.1);
  --input-color-2: rgba(23, 23, 23, 0.5);
  --input-text-color-1: #171717;

  --checkbox-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yLjg1NzI4IDkuMDk5MDJMNy4xMjI5MyAxMy4wMjAxTDE1LjYwMSAzLjY0MzciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4=');
  --dropdown-icon: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1.63637%206.54545L9%2013.9091L16.3636%206.54545%22%20stroke%3D%22%23171717%22%20stroke-width%3D%222.45455%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E');
}

[theme="dark"] {
  --bg-color-2: #171717;
  --bg-color-2-inverted: #FFFFFF;
  --bg-color-3: rgba(255, 255, 255, 0.05);
  --bg-color-4: rgba(255, 255, 255, 0.1);
  --text-color-1: #FFFFFF;
  --text-color-1-inverted: #171717;
  --text-color-2: rgba(255, 255, 255, 0.5);

  --btn-color-1: #FFFFFF;
  --btn-text-color-1: #171717;

  --input-color-1: rgba(255, 255, 255, 0.1);
  --input-color-2: rgba(255, 255, 255, 0.5);
  --input-text-color-1: #FFFFFF;

  --checkbox-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yLjg1NzI4IDkuMDk5MDJMNy4xMjI5MyAxMy4wMjAxTDE1LjYwMSAzLjY0MzciIHN0cm9rZT0iIzE3MTcxNyIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg==');
  --dropdown-icon: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1.63637%206.54545L9%2013.9091L16.3636%206.54545%22%20stroke%3D%22white%22%20stroke-width%3D%222.45455%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E');
}

.App {
  text-align: center;
  background-color: #222C42;
  background-image: url(/prize-bg.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.App.formsubmit {
  background-image: url(/hunt_for_glory_background.jpg);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

[class^="mainbox"] {
  text-align: left;
}
.text-center {
  text-align: center!important;
}

.custom-button {
  background: var(--btn-color-1);
  color: var(--btn-text-color-1);
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-weight: 600;
  border: none;
  width: 100%;
  height: 60px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 7px 15px;
  font-size: 22px;
  line-height: 26px;
  outline: none;
}

.custom-button:disabled, .custom-button.disabled {
  opacity: 0.1;
}

.custom-button:active, .custom-button:focus {
  color: var(--btn-text-color-1) !important;
  transform: scale(0.97);
  transition: all .3s ease-out;
}

.mainbox-header {
  width: calc(100% + 48px);
  margin: -16px -24px 0 -24px;
}
.mainbox-title {
  color: var(--text-color-1);
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 27.65px;
}
.mainbox-description, .mainbox-text {
  color: var(--text-color-1);
  font-size: 16px;
  line-height: 21px;
}
.mainbox-trailing {
  color: var(--text-color-1);
  opacity: 0.5;
  font-size: 12px;
}
.unstyled-list {
  padding-inline-start: 16px;
}
.unstyled-list li {
  /* list-style: none; */
}
.mainbox-form {
  margin-top: 48px;
}
.input-group {
  padding-bottom: 20px;
}
.input-group input, .input-group select {
  width: 100%;
  height: 60px;
  background: var(--input-color-1);
  color: var(--input-text-color-1);
  border: 2px solid var(--bg-color-4);
  border-radius: 7px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 18px;
  padding-right: 18px;
  outline: none;
  transition: all .3s ease-in;
}
.input-group.custom-select select {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  appearance: none;
  background-image: var(--dropdown-icon);
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: .65em auto, 100%;
  padding-right: 36px;
}
.input-group.custom-select.unset > select {
  color: var(--input-color-2)!important;
}
.input-group input::-webkit-input-placeholder,
.input-group input:-moz-placeholder,
.input-group input:-moz-placeholder,
.input-group input:-ms-input-placeholder {
  color: rgba(23, 23, 23, 0.2)!important;
}
.input-group input:focus {
  border-color: var(--input-color-2);
}
.input-group input.is-invalid {
  border-color: #ea6969;
  color: #ea6969;
}
.input-group .input-info {
  opacity: 0.2;
  float: right;
  font-weight: 300;
  font-size: 16px;
  margin-top: 10px;
}

/* The mc-checkbox */
.mc-checkbox {
  color: var(--text-color-1);
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: left;
}

/* Hide the browser's default checkbox */
.mc-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.mc-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: transparent;
  border: 2px solid var(--btn-color-1);
  border-radius: 6px;
  box-sizing: border-box;
}

/* On mouse-over, add a grey background color */
.mc-checkbox:hover input ~ .mc-checkmark {
  background-color: var(--input-color-2);
}

/* When the checkbox is checked, add a blue background */
.mc-checkbox input:checked ~ .mc-checkmark {
  background-color: var(--btn-color-1);
}

/* Create the mc-checkmark/indicator (hidden when not checked) */
.mc-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mc-checkmark when checked */
.mc-checkbox input:checked ~ .mc-checkmark:after {
  display: block;
  background-image: var(--checkbox-icon);
}

/* Style the mc-checkmark/indicator */
.mc-checkbox .mc-checkmark:after {
  left: 0;
  top: 0px;
  width: 23px;
  height: 23px;
  display: block;
  background-size: 66%;
  background-position: 25% 25%;
  background-repeat: no-repeat;
}

a {
  color: var(--text-color-1);
  font-weight: 600;
}

.fix-hyphen {
  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;
  -moz-hyphens: auto;
  -moz-hyphenate-limit-chars: 6 3 3;
  -moz-hyphenate-limit-lines: 2;
  -moz-hyphenate-limit-last: always;
  -moz-hyphenate-limit-zone: 8%;
  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: 6 3 3;
  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-last: always;
  -ms-hyphenate-limit-zone: 8%;
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
}

.g-recaptcha {
  width:100%;
  margin: 12px 0;
  transform:scale(0.95);
  -webkit-transform:scale(0.95);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
}