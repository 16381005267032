.question-image {
  width: 100%;
}
.choices {
  width: 100%;
  margin-top: 24px;
}
.choice-container {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
}
.radio-input {
  display: none; /* Hide the actual radio button */
}
.choice-text {
  cursor: pointer; /* Change cursor on hover */
  width: 100%;
  background: var(--bg-color-4);
  color: var(--text-color-1);
  border-radius: 25px;
  min-height: 44px;
  padding: 4px 18px;
  display: flex;
  font-weight: 600;
  align-items: center;
  transition: all .2s ease-out;
}
.radio-input:checked + .choice-container .choice-text, .choice-container.selected .choice-text {
  background: var(--bg-color-2-inverted);
  color: var(--text-color-1-inverted);
  transform: scale(0.985);
}
