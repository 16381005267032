#content {
  max-width: 450px;
  box-sizing: border-box;
  margin: 10px auto;
  width: 96%;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  padding: 16px 24px;
  background-color: var(--bg-color-2);
  font-size: 16px;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=email] {
  background: #FFFFFF;
  width: 100%;
  border-radius: 4px 0px 0px 4px;
  padding-left: 16px;
  color: #7C89A6;
  -moz-appearance: textfield;
}

input[type=text] {
  background: #FFFFFF;
  width: 100%;
  border-radius: 4px 0px 0px 4px;
  padding-left: 16px;
  color: #7C89A6;
  -moz-appearance: textfield;
}

input {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  top: 0px;
  -webkit-appearance: none;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 600;
  height: 69px;
  border: none;
  line-height: 36px;
}

#secondRow {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #EA6969;
  text-align: center;
}

#secondJoinRow {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  margin: 8px 0;
  color: #7C89A6;
  text-align: center;
}

.error-message {
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  margin: 12px 0;
  color: #7C89A6;
  text-align: center;
}

.squeeze {
  width: 85%;
  line-height: 30px;
  margin: 26px auto;
}

.withNewlines {
    white-space: pre-wrap;
}
